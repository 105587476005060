<template>
  <div>
    <div v-if="!isDetails">
      <div class="card" style="height: 40px">
        <div style="flex: 0; display: flex; float: left">
          <div style="width: 70px; height: 40px; margin-right: 25px">
            <el-button
              type="primary"
              plain
              icon="el-icon-upload2"
              :loading="exportLoading"
              @click="handleExport()"
              >导出</el-button
            >
          </div>
          <div style="width: 80px; height: 40px; margin-right: 25px">
            <el-button type="primary" @click="setIntegral">积分设置</el-button>
          </div>
          <div style="width: 60px; height: 40px; margin-right: 60px">
            <el-button type="primary" @click="setSavings">储值金充值</el-button>
          </div>
          <Send  :client_user_ids="client_user_id"   :client_user_master_ids="client_user_master_ids" />
        </div>
        <div style="float: right; display: flex">
          <SearchConf function_code="clientuser" />
          <TableConf function_code="clientuser" @onRowList="handleRowList" />
          <ExportConf function_code="clientuser" />
        </div>
      </div>
      <div
        style="
          padding: 10px 0;
          border: 1px solid #e8e8e8;
          border-top: none;
          background: white;
          border-radius: 0 0 5px 5px;
        "
      >
        <Search @onCond="handleCond" function_code="clientuser" />
      </div>
      <div style="margin-top: 15px; display: flex; width: 100%">
        <div class="storeList" style="width: 11%">
          <div class="storeTitle">
            <div class="span"></div>
            全部
          </div>
          <div style="min-height: 500px; max-height: 600px; overflow-y: auto">
            <template v-for="(item, index) in storeList">
              <div v-bind:key="index">
                <div
                  v-if="item.child"
                  class="store"
                  :class="
                    hierarchy_id === item.hierarchy_id ||
                    client_system_id === item.client_system_id ||
                    client_system_title === item.client_system_title
                      ? 'selectStore'
                      : null
                  "
                >
                  <div>
                    <div
                      class="text"
                      style="font-size: 15px"
                      :style="
                        client_system_id === item.client_system_id
                          ? 'color:#5493f5;'
                          : null
                      "
                      @click="handleStore(item, 'system')"
                    >
                      {{ item.client_system_title }}
                    </div>
                    <template v-for="(child, childIndex) in item.child">
                      <div
                        :key="childIndex"
                        class="childStore"
                        @click="handleStore(child, 'system_store')"
                        :style="
                          hierarchy_id === child.hierarchy_id
                            ? 'color:#5493f5;'
                            : null
                        "
                      >
                        {{ child.hierarchy_name }}
                      </div>
                    </template>
                  </div>
                </div>
                <div
                  v-else
                  class="store"
                  :class="
                    hierarchy_id === item.hierarchy_id ? 'selectStore' : null
                  "
                  :style="
                    hierarchy_id === item.hierarchy_id ? 'color:#5493f5;' : null
                  "
                >
                  <div
                    class="text"
                    style="font-size: 15px"
                    @click="handleStore(item, 'store')"
                  >
                    {{ item.hierarchy_name }}
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div style="width: 88.5%" v-if="rowList.length !== 0">
          <div class="table" style="border-top: 1px solid #e8e8e8">
            <el-table
              ref="multipleTable"
              :data="tableData"
              stripe
              tooltip-effect="dark"
              height="620"
              @selection-change="handleSelectionChange"
              v-loading="tableLoading"
              @row-dblclick="handleDalclick"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <template v-for="(item, index) in rowList">
                <el-table-column
                  align="center"
                  :key="index"
                  :prop="item.field_alias"
                  :label="item.field_text"
                  :width="item.t_width"
                  :filters="item.filed_status"
                >
                  <template slot-scope="scope">
                    <div>
                      <div v-if="item.field_alias === 'str_tag_name'">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="scope.row[item.field_alias]"
                          placement="top"
                        >
                          <div
                            style="
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: nowrap;
                            "
                          >
                            {{ scope.row[item.field_alias] }}
                          </div>
                        </el-tooltip>
                      </div>
                      <div v-else-if="item.field_alias === 'realname'">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="scope.row[item.field_alias]"
                          placement="top"
                        >
                          <div
                            style="
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: nowrap;
                            "
                          >
                            {{ scope.row[item.field_alias] }}
                          </div>
                        </el-tooltip>
                      </div>
                      <div v-else>
                        {{ scope.row[item.field_alias] }}
                      </div>
                    </div>
                  </template></el-table-column
                >
              </template>
              <el-table-column
                align="center"
                fixed="right"
                prop="operation"
                label="操作"
                width="180"
              >
                <template slot-scope="scope">
                  <div @click="membersDetail(scope.row)" style="cursor:pointer;color:#409EFF">详情</div>
                  <!-- <div class="options">
                    <el-select
                      v-model="scope.row.select"
                      placeholder="请选择"
                      @change="handleDetails($event, scope.row)"
                    >
                      <el-option label="详情" :value="1"></el-option>
                    </el-select>
                  </div> -->
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pagina">
            <el-pagination
              background
              :current-page="page"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              :page-sizes="[15, 30, 40, 50]"
              :page-size="15"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="setIntVisiable"
      width="30%"
      title="设置积分"
      :destroy-on-close="true"
      :before-close="cancelSetInt"
    >
      <el-form :model="setIntForm" :rules="intRules" ref="setIntForm">
        <div class="form">
          <el-form-item
            label="操作类型"
            :label-width="formLabelWidth"
            prop="type"
          >
            <el-radio-group v-model="setIntForm.type">
              <el-radio :label="1">增加</el-radio>
              <el-radio :label="2">扣减</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="积分值"
            :label-width="formLabelWidth"
            prop="integral"
          >
            <el-input
              v-model="setIntForm.integral"
              autocomplete="off"
              style="width: 60%"
              placeholder="请输入积分值"
            ></el-input>
            （最多可输入四位小数）
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input
              v-model="setIntForm.remarks"
              type="textarea"
              autocomplete="off"
              style="width: 60%"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </div>
        <div style="text-align: center">
          <el-button
            type="primary"
            @click="submitIntegral('setIntForm')"
            :loading="loading"
            >保存</el-button
          >
          <el-button @click="cancelSetInt">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="setSavingsVisiable"
      width="30%"
      title="储值金充值"
      :destroy-on-close="true"
      :before-close="cancelSetSavings"
    >
      <el-form :model="setSavingForm" :rules="savingRules" ref="setSavingForm">
        <div class="form">
          <el-form-item
            label="操作类型"
            :label-width="formLabelWidth"
            prop="type"
          >
            <el-radio-group v-model="setSavingForm.type">
              <el-radio :label="10">增加</el-radio>
              <el-radio :label="20">扣减</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="储值金"
            :label-width="formLabelWidth"
            prop="give_money"
          >
            <el-input
              v-model="setSavingForm.give_money"
              autocomplete="off"
              style="width: 60%"
              placeholder="请输入储蓄金金额"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input
              v-model="setSavingForm.remark"
              type="textarea"
              autocomplete="off"
              style="width: 60%"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </div>
        <div style="text-align: center">
          <el-button
            type="primary"
            @click="submitSavings('setSavingForm')"
            :loading="loading"
            >保存</el-button
          >
          <el-button @click="cancelSetSavings">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <memberDetail
      :showCoupon="showCoupon"
      v-if="isDetails"
      :client_user_id="client_user_id"
      :client_system_id="client_system_id"
      :client_user_master_id="client_user_master_id"
      :hierarchy_id="hierarchy_id"
      @hide="getList"
      memberType="wholeMember"
    />
  </div>
</template>

<script>
import SearchConf from "@/components/searchConf/Index.vue";
import TableConf from "@/components/tableConf/Index.vue";
import ExportConf from "@/components/exportConf/Index.vue";
import Search from "@/components/search/Index.vue";
import memberDetail from "@/views/member/memberManage/memberDetail/Index.vue";
import Send from "../../components/Send";
import {
  getWholeMemberListExport,
  getWholeMemberList,
  setIntegral,
  insertStorage,
  getClientSystemAndMerchants,
} from "@/api/member/memberList/index.js";
export default {
  data() {
    let validateNum = (rule, value, callback) => {
      if (!/^\d+(.\d{1,4})?$/.test(value)) {
        callback(new Error("请输入正整数，最多四位小数"));
      } else {
        callback();
      }
    };
    return {
      activeName: 'members',//标签切换
      showCoupon: true, // 详情页是否展示优惠券tab,陈鸿明留
      isDetails: false,
      exportLoading: false,
      tableData: [],
      nterprisetableData:[],
      rowList: [],
      total: 0,
      page: 1,
      limit: 12,
      hierarchy_id: null,
      client_system_id: 0,
      client_system_title: null,
      storeList: [],
      tableLoading: false,
      client_user_id: 0,
      client_user_ids: [],
      client_user_master_ids:[],
      client_user_master_id:0,
      activeName:"members",
      cond: {},
      setIntVisiable: false,
      setSavingsVisiable: false,
      setIntForm: {},
      setSavingForm: {},
      formLabelWidth: "100px",
      loading: false,
      intRules: {
        type: [
          { required: true, message: "请选择操作类型", trigger: "change" },
        ],
        integral: [
          { required: true, message: "请填写积分值", trigger: "blur" },
          { validator: validateNum, trigger: "blur" },
        ],
      },
      savingRules: {
        type: [
          { required: true, message: "请选择操作类型", trigger: "change" },
        ],
        give_money: [
          { required: true, message: "请完善储蓄金金额", trigger: "blur" },
          { validator: validateNum, trigger: "blur" },
        ],
      },
      tableLoading: false,
    };
  },
  created() {
    this.getClientSystemAndMerchants();
  },
  components: {
    Send,
    SearchConf,
    TableConf,
    ExportConf,
    Search,
    memberDetail,
  },
  methods: {//标签点击
    handleClick(tab, event) {

    },
    getClientSystemAndMerchants() {
      getClientSystemAndMerchants().then((res) => {
        console.log(res)
        if (res.code === 1) {
          if (res.data.length !== 0) {
            this.storeList = res.data;
            this.client_system_id = res.data[0].client_system_id;
            this.getList();
          }
        }
      });
    },
    /*
     *  积分设置
     */
    setIntegral() {
      if (this.client_user_ids.length === 0) {
        this.$message({
          type: "warning",
          message: "请选择会员",
        });
      } else {
        this.setIntVisiable = true;
      }
    },
    /*
     *  关闭积分设置弹窗
     */
    cancelSetInt() {
      this.setIntVisiable = false;
      this.setIntForm = {};
      this.client_user_ids = [];
      this.getList();
    },
    /*
     *  积分设置
     */
    submitIntegral() {
      this.$refs.setIntForm.validate((valid) => {
        if (valid) {
          const data = this.setIntForm;
          data.client_user_id = this.client_user_ids;
          setIntegral(data).then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "积分设置成功!",
              });
              this.cancelSetInt();
            }
          });
        }
      });
    },
    /*
     *  关闭储值金充值弹窗
     */
    cancelSetSavings() {
      this.setSavingsVisiable = false;
      this.setSavingForm = {};
      this.client_user_ids = [];
      this.getList();
    },
    /*
     *  储值金充值
     */
    setSavings() {
      if (this.client_user_ids.length === 0) {
        this.$message({
          type: "warning",
          message: "请选择会员",
        });
      } else {
        this.setSavingsVisiable = true;
      }
    },
    /*
     *  储值金充值
     */
    submitSavings() {
      this.$refs.setSavingForm.validate((valid) => {
        if (valid) {
          const data = this.setSavingForm;
          data.client_user_id = JSON.stringify(this.client_user_ids);
          insertStorage(data).then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "储值金充值成功!",
              });
              this.cancelSetSavings();
            }
          });
        }
      });
    },
    // 处理表格数据
    handleRowList(rowList) {
      this.rowList = [];
      this.rowList = rowList.filter((item) => item.field_alias !== "avatar");
    },

    /*
     *  选择门店
     */
    handleStore(item, type) {
      this.page = 1;
      this.limit = 15;
      if (type === "system") {
        this.hierarchy_id = 0;
        this.client_system_id = item.client_system_id;
        this.client_system_title = null;
      }
      if (type === "store") {
        this.hierarchy_id = item.hierarchy_id;
        this.client_system_id = 0;
        this.client_system_title = null;
      }
      if (type === "system_store") {
        this.hierarchy_id = item.hierarchy_id;
        this.client_system_id = 0;
        this.client_system_title = item.client_system_title;
      }

      this.getList();
    },
    /*
     *  导出
     */
    handleExport() {
      if (JSON.stringify(this.cond) === "{}") {
        this.cond = {
          page: 1,
          limit: this.total,
        };
      }
      const data = {
        hierarchy_id: this.hierarchy_id,
        client_system_id: this.client_system_id,
        ...this.cond,
        function_code: "clientuser",
      };
      this.exportLoading = true;
      getWholeMemberListExport(data)
        .then((res) => {
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute("download", `全部会员列表导出.xlsx`);
          a.href = URL.createObjectURL(blob);
          a.click();
          this.exportLoading = false;
        })
        .catch((res) => {
          this.$message({
            type: "error",
            message: res.msg,
          });
          this.exportLoading = false;
        });
    },//全部会员列表
    getList() {
      this.tableLoading = true;
      this.isDetails = false;
      const data = {
        ...this.cond,
        page: this.page,
        limit: this.limit,
      };

      if (this.client_system_id !== 0) {
        data.client_system_id = this.client_system_id;
      }

      if (this.hierarchy_id !== 0) {
        data.hierarchy_id = this.hierarchy_id;
      }

      getWholeMemberList(data).then((res) => {
        if (res.code === 1) {
          this.tableLoading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    handleDalclick(row) {
      this.client_user_id = row.client_user_id;
      this.client_user_master_id=row.client_user_master_id
      this.hierarchy_id=row.hierarchy_id;
      this.isDetails = true;
    },//会员详情
    membersDetail(row){
      this.isDetails = true;
      this.client_user_id = row.client_user_id;
      this.hierarchy_id=row.hierarchy_id;
      this.client_user_master_id=row.client_user_master_id
    },
    // handleDetails(event, row) {
    //   if (event === 1) {
    //     this.isDetails = true;
    //     this.client_user_id = row.client_user_id;
    //     this.hierarchy_id=row.hierarchy_id;
    //     this.client_user_master_id=row.client_user_master_id
    //   }
    // },
    // 选择发放优惠券*****
    handleSelectionChange(val) {
      let arr = [];
      val.forEach((item) => {
        arr.push(item.client_user_id);
      });
      this.client_user_ids = [...new Set(arr)];
      let arr2=[];
      val.forEach((item) => {
        arr2.push(item.client_user_master_id);
      });
      this.client_user_master_ids = [...new Set(arr2)];
    },
    handleCond(cond) {
      this.cond = { ...cond };
      this.page = 1;
      this.getList();
    },
    getImportFile() {},
    handleImportSuccess() {},
    handleSizeChange(val) {
      this.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.storeList {
  padding-top: 22px;
  margin-right: 10px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;

  .storeTitle {
    margin-bottom: 5px;
    margin-left: 15px;
    display: flex;
    flex-direction: row;
    font-size: 16px;

    .span {
      margin: auto 10px auto 0;
      width: 4px;
      height: 16px;
      background: #5493f5;
      border-radius: 2px;
    }
  }
  .childStore {
    margin: 5px 0 5px 35px;
    line-height: 35px;
  }

  .store {
    padding-left: 17px;
    width: 100%;
    background: white;
    padding: 10px 0;
    font-size: 14px;

    .text {
      margin-left: 15px;
    }
  }
  .store:hover {
    cursor: pointer;
  }

  .selectStore {
    background-color: #ecf5ff;
  }
}
</style>
